import React from "react";
import SwiperCore, { Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { Link } from "gatsby";

SwiperCore.use([Pagination, Autoplay]);

const Review = (props) => {
    return(
                    <>
              <p className="text-xl pb-8">
                {props.children}
              </p>
              <p className="font-semi-bold text-xl mb-16">- {props.name}</p>
            </>

    )
}


export default function ReviewCol(props) {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  pagination.displayName = "Pagenator";

  return (
    <section className={`bg-white text-black relative ${props.className}`}>
      <div className="pt-16 md:pt-24 pb-32 md:pb-48 max-w-7xl mx-auto px-4">
        <div>
          <h2 className="uppercase text-black text-xl uppercase">
            SEE FOR YOURSELF
          </h2>
          <p className="text-black text-4xl pt-4">
            Hear from some of <br />
            our incredible members
          </p>
        </div>
        <div className="pt-12">
          <Swiper
            // install Swiper modules
            modules={[Pagination]}
            slidesPerView={1}
            pagination={pagination}
            onSwiper={(swiper) => console.log(swiper)}
            spaceBetween={40}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              400: {
                slidesPerView: 1,
              },
              800: {
                slidesPerView: 2,
              },
              9999: {
                slidesPerView: 2,
              },
            }}
          >
          <SwiperSlide>

          <Review name={`Matthew Sawyers, KORE Software`}>
          “Collective 100 is a great place to setup a work base for a small team. They’ve created a modern, professional environment in a great location close to cafes and public transport. Barry and the team are friendly and always willing to help. I would recommend to anyone looking for a step up from the home office.”
        </Review>  
           </SwiperSlide>
                     <SwiperSlide>

          <Review name={`Gav Hill, E2E Security`}>
“Can’t recommend highly enough! We checked out various co-working spaces and nothing else delivers to the level that Collective_100 does.Collective_100 provides a beautiful space with great vibes! It strikes the right balance between modern and sleek whilst being open, well furnished and well lit.”        </Review>  
           </SwiperSlide>

          <SwiperSlide>

          <Review name={`Ben Pascoe, Pastor Australia`}>
“Collective 100 couldn’t have made my search for a new home for a new business venture any easier. Great building, great communication and good old fashion family feel.Facilities are better than anything I looked at.Very happy with the whole experience.
Thanks to you all.”        </Review>  
           </SwiperSlide>

          
          </Swiper>
        </div>
        <div className="pt-14">
          <Link to="/contact">
          <p className="underline text-lg pt-2">Get in touch</p>

          </Link>
        </div>
      </div>
    </section>
  );
}
