import React, { useState } from "react";

const StyledInput = (props) => {
  return (
    <input
      className={`w-full focus:shadow-none focus:outline-none border-t-0 border-l-0 border-r-0 border-b focus:border-black border-gray-400 bg-white px-0`}
      placeholder={props.placeholder}
      type={props.type}
      required={props.required}
      name={props.name || props.placeholder}
    />
  );
};

const StyledTextAreaInput = (props) => {
  return (
    <textarea
      className={`w-full focus:shadow-none focus:outline-none border-t-0 border-l-0 border-r-0 border-b focus:border-black border-gray-400 bg-white px-0`}
      placeholder={props.placeholder}
      type={props.type}
      rows={props.rows || 4}
      required={props.required}
      name={props.name || props.placeholder}
    />
  );
};

const StyledLabel = (props) => {
  return <label className="text-xl">{props.children}</label>;
};

const RadioItem = (props) => {
  return (
    <>
      <p
        className={`rounded-full transition-all capitalize px-3 py-1 border cursor-pointer border-black text-center ${
          props.value == props.selected
            ? `border-black text-black`
            : `border-gray-400 text-gray-400`
        }
        hover:border-gray-300 mr-4 mt-2
        `}
        onClick={() => props.setSelected(props.value)}
      >
        {props.value}
      </p>
    </>
  );
};

const ContactSection = () => {
  const interest_items = [
    `Private Office`,
    `Dedicated Desk`,
    `Flexible Workspace`,
    `Meeting Room`,
    `Virtual Office`,
    `Daily Office`,
  ];
  const [interest, setInterest] = useState(interest_items[0]);

  return (
    <div className="flex items-center min-h-screen">
      <div className="grid lg:grid-cols-1 custom-container w-full mx-auto relative">
        <div className="px-8 lg:max-w-4xl mx-auto w-full justify-center">
          <form
            className="space-y-4"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="Contact"
            action="/thankyou/"
            method="POST"
          >
            <input type="hidden" name="form-name" value="Contact" />

            <div className="flex flex-col space-y-2">
              <StyledLabel>I’m interested in a…</StyledLabel>

              <div className="flex items-around flex-wrap">
                {interest_items.map((value, index) => {
                  return (
                    <RadioItem
                      setSelected={setInterest}
                      selected={interest}
                      value={value}
                      key={`interest-${index}`}
                    />
                  );
                })}

                <input
                  name="Interested in"
                  id="interested_in"
                  className="hidden"
                  value={interest}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <p className="hidden">
                <label>
                  Don’t fill this out if you’re human:{" "}
                  <input name="bot-field" />
                </label>
              </p>

              <StyledLabel>How we can contact you…</StyledLabel>

              <StyledInput
                name="Name"
                placeholder="Name*"
                type={`text`}
                required
              />
              <StyledInput
                name="Company"
                placeholder="Company"
                type={`text`}
              />
              <StyledInput
                name="Phone Number"
                placeholder="Phone Number*"
                type={`tel`}
              />
              <StyledInput name="Email" placeholder="Email*" type={`email`} />
              <StyledTextAreaInput
                placeholder="Message"
                type={`text`}
                name="Message"
              />
            </div>

            <button
              type="Submit"
              className={`rounded-full transition-all capitalize px-3 py-2 border cursor-pointer border-black text-center ${
                interest
                  ? `border-black text-black`
                  : `border-gray-400 text-gray-400`
              }`}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
