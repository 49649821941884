import React from "react";
import VideoFile from "../../videos/c100_reduce.mp4";
import VideoFileWebM from "../../videos/c100_reduce.webm";

export default function Video() {
    return (
        <section className='overflow-hidden relative' style={{backgroundColor: "#121212", maxHeight: "760px"}}>
            <video className="w-full h-full relative block object-cover object-center origin" frameBorder={0} loop muted playsInline autoPlay>
            <source src={VideoFileWebM} type="video/webm" />
            <source src={VideoFile} type="video/mp4" />

            </video>
        </section>
    )
}
