import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactSection from "../sections/landing/form";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <ContactSection/>

    </Layout>
  );
}

export default IndexPage;
