import React from "react";
import Kitchen from "../../images/kitchen.svg";
import Lockers from "../../images/lockers.svg";
import Meet from "../../images/meet.svg";
import Park from "../../images/park.svg";
import Shower from "../../images/shower.svg";
import Wifi from "../../images/wifi.svg";
import Access from "../../images/access.svg";
import Bike from "../../images/bike.svg";
import Cleaning from "../../images/cleaning.svg";
import Concierge from "../../images/services.svg";
import Bus from "../../images/bus.svg";
import Virtual from "../../images/virtual.svg";

import { Link } from "gatsby";
import Marquee from "react-fast-marquee";
import { useInView } from 'react-intersection-observer';

const Amenity = (props) => {
  
  return (
    <div className={`flex flex-row space-x-4 py-6 items-center mb-4`} style={{opacity: props.inView ? 100 : 0, transition: `opacity 300ms`, transitionDelay: `${(100 * props.idx)}ms`}}>
      <img
        src={props.image}
        className="w-14 h-14 md:h-14 md:w-14 mx-4 md:mx-0"
      />
      <p className="text-xl font-regular px-4 hidden md:block">
        {props.children}
      </p>
    </div>
  );
};

const AmenityMob = (props) => {
  return (
    <div className="flex flex-row space-x-4 py-6 items-center mb-4">
      <img
        src={props.image}
        className="w-14 h-14 md:h-14 md:w-14 mx-4 md:mx-0"
      />
      <p className="text-xl font-regular px-4 hidden md:block">
        {props.children}
      </p>
    </div>
  );
};


const Amenities = () => {


  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 200,
    fallbackInView: true,
    triggerOnce: true
  });

  return (
    <section className={`bg-white text-black relative`}>
      <div className="pt-32 md:pt-52 md:pb-40 px-4 max-w-7xl mx-auto">
        <div className="max-w-md">
          <p className="uppercase text-black text-xl">Everything you need</p>
          <h2 className="text-black text-3xl md:text-4xl pt-4">
            Premium amenities
          </h2>
        </div>
        <div className="hidden py-6 md:grid md:grid-cols-4 pt-8 md:py-6" ref={ref}>
          <Amenity inView={inView} image={Kitchen} idx={1}>Full Kitchen</Amenity>
          <Amenity inView={inView} image={Lockers} idx={2}>Dedicated Lockers</Amenity>
          <Amenity inView={inView} image={Meet} idx={3}>Dedicated Meeting Rooms</Amenity>
          <Amenity inView={inView} image={Park} idx={4}>Parking on site</Amenity>
          <Amenity inView={inView} image={Shower} idx={5}>Shower Available</Amenity>
          <Amenity inView={inView} image={Wifi} idx={6}>Super Fast Internet</Amenity>
          <Amenity inView={inView} image={Access} idx={7}>24×7 Access Membership</Amenity>
          <Amenity inView={inView} image={Bike} idx={8}>Bike Racks</Amenity>
          <Amenity inView={inView} image={Cleaning} idx={9}>Office Cleaning Included</Amenity>
          <Amenity inView={inView} image={Concierge} idx={10}>Concierge Services</Amenity>
          <Amenity inView={inView} image={Virtual} idx={11}>Virtual Office</Amenity>
          <Amenity inView={inView} image={Bus} idx={12}>Easy to reach with public transport</Amenity>
        </div>

        <div className="py-8 md:hidden">
          <Marquee speed={30} gradient={false}>
            <AmenityMob image={Kitchen}>Full Kitchen</AmenityMob>
            <AmenityMob image={Lockers}>Dedicated Lockers</AmenityMob>
            <AmenityMob image={Meet}>Dedicated Meeting Rooms</AmenityMob>
            <AmenityMob image={Park}>Parking on site</AmenityMob>
            <AmenityMob image={Shower}>Shower Available</AmenityMob>
            <AmenityMob image={Wifi}>Super Fast Internet</AmenityMob>
            <AmenityMob image={Access}>24×7 Access Membership</AmenityMob>
            <AmenityMob image={Bike}>Bike Racks</AmenityMob>
            <AmenityMob image={Cleaning}>Office Cleaning Included</AmenityMob>
            <AmenityMob image={Concierge}>Concierge Services</AmenityMob>
            <AmenityMob image={Virtual}>Virtual Office</AmenityMob>
            <AmenityMob image={Bus}>Easy to reach with public transport</AmenityMob>
          </Marquee>
        </div>
        <div className="text-xl">
          <Link to="/contact" className="underline">
            Get in touch
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Amenities;
