import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Amenities from "../sections/landing/amenities";
import Hero from "../sections/landing/hero";
import ImageBlock from "../sections/landing/imageBlock";
import OurOfferings from "../sections/landing/offerings";
import ReviewCol from "../sections/landing/reviews";
import TextCol from "../sections/landing/textCol";
import Video from "../sections/landing/video";
import Map from "../sections/landing/map";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <Hero />
      <Video />
      <Amenities />
      <OurOfferings />
      <TextCol
        subtitle={`
      LETS GROW TOGETHER
      `}
        title={`A vibrant community \n
        for you to join`}
        cta={`Get in touch`}
        className="pb-32 md:pb-0"
      >
        <div>
          <p className="text-xl">
            Designed with a discerning eye, collective_100 offers all the modern
            features and seamless facilities you seek. Discover why we are the
            chosen destination for many multinational companies members who know
            the power of a stunning work environment. Our members are unified by
            many common values, of which we try to nurture at collective_100.
            Flexible memberships, through to full time dedicated offices: What
            does your brand need to succeed?
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            Leave behind the outdated lease model, and move your brand into the
            future of work. Flex-working at collective 100 allows for a
            flexibility that suits most established or growing businesses,
            thanks to our commitment and understanding of your needs. We have
            designed every touch point in our space with prestige, comfort and
            impact in mind.{" "}
          </p>
        </div>
      </TextCol>
      <ImageBlock className="pb-32 md:pb-0" />
      <ReviewCol className="pb-32 md:pb-0" />
      <Map/>

    </Layout>
  );
}

export default IndexPage;
