/* eslint-disable */
import React, { useState, useRef, useLayoutEffect } from "react";
import mapboxgl from '!mapbox-gl'
mapboxgl.accessToken = "pk.eyJ1Ijoia3lub24iLCJhIjoiY2t5MTBzZzg2MDZocDJvbnc4NGI2bzZhaiJ9.TRuhYn_BA1m686QaUiWipw";
import 'mapbox-gl/dist/mapbox-gl.css';

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(144.9922);
  const [lat, setLat] = useState(-37.8290);
  const [zoom, setZoom] = useState(15);
  useLayoutEffect(() => {
    if (map.current) return; // initialize map only once
    var map_object = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [lng, lat],
      zoom: zoom,
    });
        


        const marker = new mapboxgl.Marker({
            color: "#FFFFFF",
            draggable: false
            }).setLngLat([lng, lat])
            .addTo(map_object);
            

    map.current = map_object;

    map_object.on('idle',function(){
      map_object.resize()
      })
      
        
  });

  return (
    <section className="overflow-hidden">
      <div ref={mapContainer} className="map-container"/>
    </section>
  );
};

export default Map;
