import React from "react";

import g_1 from "../../images/home_image_4.jpg";
import g_2 from "../../images/home_image_2.jpg";
import g_3 from "../../images/home_image_1.jpg";


import g_1_webp from "../../images/webp/home_image_4.webp";
import g_2_webp from "../../images/webp/home_image_2.webp";
import g_3_webp from "../../images/webp/home_image_1.webp";

export default function ImageBlock(props) {
  return (
    <section
      className={`bg-white image-block text-black relative ${
        props.className ? props.className : ``
      }`}
    >
      <div className="py-8 md:py-24 max-w-7xl mx-auto px-4">
        <div className="grid grid-rows-2 grid-flow-col gap-4 md:gap-4 2xl:gap-16 image-block">
          <div className="row-span-3">
            <picture>
            <source srcSet={g_1_webp} type="image/webp" />
        <source srcSet={g_1} type="image/jpeg" />

            <img className="h-full w-full object-cover" src={g_1} />

            </picture>
          </div>
          <div className="col-span-1">
            <picture>
            <source srcSet={g_2_webp} type="image/webp" />
        <source srcSet={g_2} type="image/jpeg" />

            <img className="w-full h-full object-cover" src={g_2} />

            </picture>
          </div>
          <div className="row-span-2 col-span-1">
            <picture>
            <source srcSet={g_3_webp} type="image/webp" />
        <source srcSet={g_3} type="image/jpeg" />

            <img className="w-full h-full object-cover" src={g_3} />

            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
