import React from "react";
import { Link } from "gatsby";

export default function Hero() {
  return (
    <section className="relative text-center bg-white">
      <div
        className="flex items-center justify-center hero-block"
        style={{ minHeight: "350px" }}
      >
        <div className="py-32 md:py-48 mt-8">
          <h1 className="text-3xl md:text-5xl text-black">
            A Melbourne Co-Working
            <br />
            Collective Here To Boost
            <br />
            Your Productivity{" "}
          </h1>
          <div className="pt-6">
            <Link className="text-black text-xl underline" to="/contact">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
